//import Vue from "vue";
import Vuex from "vuex";
//Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:
      (localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"))) ||
      "",
    info:
      (localStorage.getItem("info") &&
        JSON.parse(localStorage.getItem("info"))) ||
      "",
    editedTarget: "",
    checkHeader: "",
    /* brand:
      (localStorage.getItem("brand") &&
        JSON.parse(localStorage.getItem("brand"))) ||
      "",
    product:
      (localStorage.getItem("product") &&
        JSON.parse(localStorage.getItem("product"))) ||
      "",
    product_model:
      (localStorage.getItem("product_model") &&
        JSON.parse(localStorage.getItem("product_model"))) ||
      "", */
  },
  mutations: {
    setInfo(state, info) {
      state.info = info;
      localStorage.setItem("info", JSON.stringify(info));
    },
    setInfoPreferOS(state, preferOS) {
      if (state.info) {
        state.info.preferOS = preferOS;
      } else {
        state.info = {
          preferOS: preferOS,
        };
      }
      localStorage.setItem("info", JSON.stringify(state.info));
    },
    logout(state) {
      state.token = "";
      state.info = "";
      localStorage.removeItem("token");
      localStorage.removeItem("info");
    },
  },
  actions: {},
});
