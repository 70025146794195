<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      <div class="row" v-if="system == 'iOS'">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="App Store專案數量"
            type="gradient-orange"
            :sub-title="topStatus.AppStoreListCount.toString()"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.IosLaunchedThisMonth }}
              </span>
              <span class="text-nowrap">本月新上架IOS APP</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="企業版APP數量"
            type="gradient-green"
            :sub-title="topStatus.InnerIosListCount.toString()"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2">
                <!-- <i class="fa fa-arrow-up"></i> {{ topStatus.newIosVersionCount }} -->
              </span>
              <span class="text-nowrap"></span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="App Store最新上架"
            type="gradient-info"
            :sub-title="topStatus.IosLatestLaunchApp"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.IosLatestLaunchEditor }} 更新於
              </span>
              <span class="text-nowrap">{{ topStatus.IosLatestLaunchAt }}</span>
            </template>
          </stats-card>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Google Store專案數量"
            type="gradient-orange"
            :sub-title="topStatus.GoogleStoreListCount.toString()"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.AndroidLaunchedThisMonth }}
              </span>
              <span class="text-nowrap">本月新上架Android APP</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="企業版APP數量"
            type="gradient-green"
            :sub-title="topStatus.InnerAndroidListCount.toString()"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2">
                <!-- <i class="fa fa-arrow-up"></i> {{ topStatus.newAndroidVersionCount }} -->
              </span>
              <span class="text-nowrap"></span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="Google Store最新上架"
            type="gradient-info"
            :sub-title="topStatus.AndroidLatestLaunchApp"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.AndroidLatestLaunchEditor }} 更新於
              </span>
              <span class="text-nowrap">{{
                topStatus.AndroidLatestLaunchAt
              }}</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="mb-8"></div>

    <div class="container-fluid mt--7">
      <div class="custom-control custom-radio">
        <div class="row justify-content-center">
          <div class="col-lg-1">
            <input
              id="ios"
              type="radio"
              class="custom-control-input"
              value="iOS"
              v-model="this.system"
              v-on:click="this.setPrefered('iOS')"
            />
            <label
              for="ios"
              class="custom-control-label"
              style="font-size: larger"
              >iOS</label
            >
          </div>
          <div class="col-lg-1">
            <input
              id="android"
              type="radio"
              class="custom-control-input"
              value="Android"
              v-model="this.system"
              v-on:click="this.setPrefered('Android')"
            />
            <label
              for="android"
              class="custom-control-label"
              style="font-size: larger"
              >Android</label
            >
          </div>
        </div>
      </div>

      <!--Tables-->
      <div class="row mt-5" v-if="system == 'iOS'">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <app-store-table></app-store-table>
        </div>
        <div class="col-xl-6">
          <inner-ios-table></inner-ios-table>
        </div>
      </div>
      <div class="row mt-5" v-else>
        <div class="col-xl-6 mb-5 mb-xl-0">
          <google-store-table></google-store-table>
        </div>
        <div class="col-xl-6">
          <inner-android-table></inner-android-table>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import AppStoreTable from "./Dashboard/AppStoreTable";
import InnerIosTable from "./Dashboard/InnerIosTable";
import GoogleStoreTable from "./Dashboard/GoogleStoreTable";
import InnerAndroidTable from "./Dashboard/InnerAndroidTable";
export default {
  components: {
    AppStoreTable,
    InnerIosTable,
    GoogleStoreTable,
    InnerAndroidTable,
  },
  data() {
    return {
      system: "iOS",
      topStatus: {
        AppStoreListCount: 5,
        GoogleStoreListCount: 5,
        InnerIosListCount: 5,
        InnerAndroidListCount: 5,
        IosLaunchedThisMonth: 1,
        IosLatestLaunchApp: "Opro9Home4 6.1.3版",
        IosLatestLaunchAt: "2021-08-04 12:05:41",
        IosLatestLaunchEditor: "Jerry",
        AndroidLaunchedThisMonth: 1,
        AndroidLatestLaunchApp: "榮總痛管家 4.0.0版",
        AndroidLatestLaunchAt: "2021-08-04 12:06:04",
        AndroidLatestLaunchEditor: "Eric",
      },
    };
  },
  methods: {
    getTopStatus() {
      this.$axios
        .get(this.$config.$api_url + "/get/TopStatus", {})
        .then((topStatus) => {
          this.topStatus = topStatus.data;
        });
    },
    setPrefered(value) {
      this.$store.commit("setInfoPreferOS", value);
    },
  },
  beforeMount() {
    if (this.$store.state.info.preferOS == "Android") {
      this.system = "Android";
    } else {
      this.system = "iOS";
    }
    this.getTopStatus();
  },
};
</script>
<style></style>
