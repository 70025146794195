/* eslint-disable prettier/prettier */
import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import Profile from "../views/UserProfile.vue";
import CreateAppStore from "../views/CreateAppStore.vue";
import CreateGoogleStore from "../views/CreateGoogleStore.vue";
import EditAppStore from "../views/EditAppStore.vue";
import EditGoogleStore from "../views/EditGoogleStore.vue";
import CreateInnerAppIOS from "../views/CreateInnerIOS.vue";
import CreateInnerAppAndroid from "../views/CreateInnerAndroid.vue";
import EditInnerAppIOS from "../views/EditInnerIOS.vue";
import EditInnerAppAndroid from "../views/EditInnerAndroid.vue";
import UploadInnerAppIOS from "../views/UploadInnerIOS.vue";
import UploadInnerAppAndroid from "../views/UploadInnerAndroid.vue";
import EditUsers from "../views/EditUsers.vue";
import AutoCheck from "../views/AutoCheck.vue";
import EditTarget from "../views/EditTarget.vue";
import AddTarget from "../views/AddTarget.vue";
import Tables from "../views/Tables.vue";
import BatchGen from "../views/BatchGen.vue";
import SyncKit from "../views/SyncKit.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

const routes = [{
        path: "/",
        redirect: "/dashboard",
        component: DashboardLayout,
        children: [{
                path: "/dashboard",
                name: "dashboard",
                components: { default: Dashboard },
            },
            {
                path: "/icons",
                name: "icons",
                components: { default: Icons },
            },
            {
                path: "/maps",
                name: "maps",
                components: { default: Maps },
            },
            {
                path: "/profile",
                name: "profile",
                components: { default: Profile },
            },
            {
                path: "/tables",
                name: "tables",
                components: { default: Tables },
            },
            {
                path: "/create-app-store",
                name: "新增 APP STORE 資訊",
                components: { default: CreateAppStore },
            },
            {
                path: "/create-google-store",
                name: "新增 GOOGLE STORE 資訊",
                components: { default: CreateGoogleStore },
            },
            {
                path: "/edit-app-store",
                name: "編輯 APP STORE 狀態",
                components: { default: EditAppStore },
            },
            {
                path: "/edit-google-store",
                name: "編輯 GOOGLE STORE 狀態",
                components: { default: EditGoogleStore },
            },
            {
                path: "/create-inner-ios",
                name: "新增 iOS 內部APP資訊",
                components: { default: CreateInnerAppIOS },
            },
            {
                path: "/create-inner-android",
                name: "新增 Android 內部APP資訊",
                components: { default: CreateInnerAppAndroid },
            },
            {
                path: "/edit-inner-ios",
                name: "編輯 iOS 內部APP 狀態",
                components: { default: EditInnerAppIOS },
            },
            {
                path: "/edit-inner-android",
                name: "編輯 Android 內部APP 狀態",
                components: { default: EditInnerAppAndroid },
            },
            {
                path: "/upload-inner-ios",
                name: "上傳 iOS 內部APP",
                components: { default: UploadInnerAppIOS },
            },
            {
                path: "/upload-inner-android",
                name: "上傳 Android 內部APP",
                components: { default: UploadInnerAppAndroid },
            },
            {
                path: "/edit-users",
                name: "權限管理",
                components: { default: EditUsers },
            },
            {
                path: "/auto-check",
                name: "AutoCheck",
                components: { default: AutoCheck },
            },
            {
                path: "/edit-target",
                name: "EditTarget",
                components: { default: EditTarget },
            },
            {
                path: "/add-target",
                name: "AddTarget",
                components: { default: AddTarget },
            },
            {
                path: "/batch-gen",
                name: "BatchGen",
                components: { default: BatchGen },
            },
            {
                path: "/synckit",
                name: "SyncKit",
                components: { default: SyncKit },
            },
        ],
    },
    {
        path: "/",
        redirect: "login",
        component: AuthLayout,
        children: [{
                path: "/login",
                name: "login",
                components: { default: Login },
            },
            {
                path: "/register",
                name: "register",
                components: { default: Register },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    routes,
});

export default router;