<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="最新 APP 資訊"
            type="gradient-orange"
            :sub-title="'Ver: ' + appStates.Ver"
            icon="ni ni-spaceship"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text">{{ appStates.Meta }}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="最新 OS 資訊"
            type="gradient-info"
            :sub-title="'Ver: ' + osStates.Ver"
            icon="ni ni-planet"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text">{{ osStates.Meta }}</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="mb-8"></div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-xl-4">
                    <h3 class="mb-0">SyncKit OTA 服務</h3>
                  </div>
                </div>
              </div>
            </template>
            <tabs fill class="flex-column flex-md-row">
              <card shadow>
                <tab-pane title="APP OTA" @click="changeTab('App')">
                  <div v-if="uploadingAPP">
                    <label class="form-control-label">上傳中...請稍候</label>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        label="新版本號"
                        placeholder="必須輸入"
                        input-classes="form-control-alternative"
                        v-model="uploadAPP.Ver"
                      />
                    </div>
                    <div class="col-lg-6 mb-4">
                      <label class="form-control-label"
                        >新版APP檔案(apk格式/無選擇則為更新資訊)</label
                      >
                      <form>
                        <input
                          type="file"
                          ref="file"
                          class="form-control"
                          @change="selectApp"
                          accept=".apk"
                        />
                      </form>
                    </div>
                    <div class="col-lg-12">
                      <base-input
                        label="附加資訊"
                        placeholder="選擇性輸入"
                        input-classes="form-control-alternative"
                        v-model="uploadAPP.Meta"
                      />
                    </div>
                    <div class="text-right col-12">
                      <a class="btn btn btn-primary" v-on:click="doUploadAPP"
                        ><em class="fa fa-upload"></em> 上傳</a
                      >
                    </div>
                  </div>
                </tab-pane>
                <tab-pane title="OS OTA" @click="changeTab('OS')">
                  <div v-if="uploadingOS">
                    <label class="form-control-label">上傳中...請稍候</label>
                    <div>
                      <label>已經完成：{{ done }} 於 全部 {{ total }}</label>
                    </div>
                    <div>
                      <label>已經完成：{{ getPercent().toFixed(2) }} %</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        label="新版本號"
                        placeholder="必須輸入"
                        input-classes="form-control-alternative"
                        v-model="register.Ver"
                      />
                    </div>
                    <div class="col-lg-6 mb-4">
                      <label class="form-control-label"
                        >新版OS檔案(zip格式/無選擇則為更新資訊)</label
                      >
                      <form>
                        <input
                          type="file"
                          ref="file"
                          class="form-control"
                          @change="selectFile"
                          accept=".zip"
                        />
                      </form>
                    </div>
                    <div class="col-lg-12">
                      <base-input
                        label="附加資訊"
                        placeholder="選擇性輸入"
                        input-classes="form-control-alternative"
                        v-model="register.Meta"
                      />
                    </div>
                    <div class="text-right col-12">
                      <a class="btn btn btn-primary" v-on:click="doUploadOS">
                        <em class="fa fa-upload"></em>
                        上傳</a
                      >
                    </div>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import { Permission, permFlagDefine } from "@/utils/permission";
import Card from "../components/Card.vue";
import request from "@/utils/request";
import AWS from "aws-sdk";
import {
  CreateMultipartUploadCommand,
  UploadPartCommand,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
  S3Client,
} from "@aws-sdk/client-s3";
export default {
  name: "SyncKit",
  components: { Card },
  data() {
    return {
      synckitManager: false,
      nowMode: "",
      selected: "",
      file: undefined,
      uploadingOS: false,
      uploadingAPP: false,
      total: 1,
      done: 0,
      register: {
        Ver: "",
        Meta: "",
        File: "",
      },
      uploadAPP: {
        file: undefined,
        Meta: "",
        Ver: "",
      },
      appStates: {
        Ver: "1.0",
        Meta: "123",
        Path:
          "https://synckit.s3.ap-northeast-1.amazonaws.com/ota/829b3b9347241cbd0ace765eff22a581.apk",
      },
      osStates: {
        Ver: "1.0.0",
        Meta: "Base",
        Path:
          "https://synckit.s3.ap-northeast-1.amazonaws.com/ota/7a44687947563d3c3e7ade0a1bbbd07f.bin.zip",
      },
    };
  },
  methods: {
    getPercent() {
      if (this.total == 0) return 0;
      return (this.done / this.total) * 100;
    },
    selectFile() {
      this.file = this.$refs.file.files[0];
    },
    selectApp() {
      this.uploadAPP.file = this.$refs.file.files[0];
      console.log(this.uploadAPP.file.name);
    },
    async getStates() {
      await request
        .get("/SyncKit/App")
        .then((response) => {
          this.appStates = response.data;
        })
        .catch((error) => {
          console.log(error);
          location.href = this.$config.$project_folder_name + "/dashboard";
        });
      await request
        .get("/SyncKit/OS")
        .then((response) => {
          this.osStates = response.data;
        })
        .catch((error) => {
          console.log(error);
          location.href = this.$config.$project_folder_name + "/dashboard";
        });
    },
    async registerOS() {
      this.register.File = this.file.name;
      await request
        .post(
          "/SyncKit/OS",
          { ...this.register },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.getStates();
          alert("已成功上傳與新增OS");
          this.uploadingOS = false;
        })
        .catch((error) => {
          console.log(error);
          this.uploadingOS = false;
        });
    },
    async uploadAWSmulti(cred) {
      const chunkSize = 1024 * 1024 * 5;
      const bucketName = "synckit";
      const region = "ap-northeast-1";
      let key = "ota/" + this.file.name;
      const s3Client = new S3Client({
        region: region,
        credentials: {
          accessKeyId: cred.AccessKeyId,
          expireTime: cred.Expiration,
          secretAccessKey: cred.SecretAccessKey,
          sessionToken: cred.SessionToken,
        },
      });
      let uploadId;
      try {
        const multipartUpload = await s3Client.send(
          new CreateMultipartUploadCommand({
            Bucket: bucketName,
            Key: key,
          })
        );
        const numberOfparts = Math.ceil(this.file.size / chunkSize);
        this.total = numberOfparts;
        this.done = 0;
        console.log("Total Parts: ", numberOfparts);
        uploadId = multipartUpload.UploadId;
        const uploadPromises = [];
        // Upload each part.
        for (let i = 0; i < numberOfparts; i++) {
          const sentSize = i * chunkSize;
          const chunk = this.file.slice(sentSize, sentSize + chunkSize);
          uploadPromises.push(
            s3Client
              .send(
                new UploadPartCommand({
                  Bucket: bucketName,
                  Key: key,
                  UploadId: uploadId,
                  Body: chunk,
                  PartNumber: i + 1,
                })
              )
              .then((d) => {
                console.log("Part", i + 1, "uploaded");
                this.done++;
                return d;
              })
          );
        }
        const uploadResults = await Promise.all(uploadPromises);
        console.log("All done!");
        await s3Client.send(
          new CompleteMultipartUploadCommand({
            Bucket: bucketName,
            Key: key,
            UploadId: uploadId,
            MultipartUpload: {
              Parts: uploadResults.map(({ ETag }, i) => ({
                ETag,
                PartNumber: i + 1,
              })),
            },
          })
        );
        this.uploadingOS = false;
      } catch (err) {
        console.error(err);
        if (uploadId) {
          const abortCommand = new AbortMultipartUploadCommand({
            Bucket: bucketName,
            Key: key,
            UploadId: uploadId,
          });

          await s3Client.send(abortCommand);
          this.uploadingOS = false;
        }
      }
    },
    uploadAWS(cred) {
      const bucketName = "synckit";
      const region = "ap-northeast-1";
      AWS.config.update({
        region: region,
        credentials: {
          accessKeyId: cred.AccessKeyId,
          expireTime: cred.Expiration,
          secretAccessKey: cred.SecretAccessKey,
          sessionToken: cred.SessionToken,
        },
      });
      let fileKey = "ota/" + this.file.name;
      let upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: bucketName,
          Key: fileKey,
          Body: this.file,
        },
      });
      let promise = upload.promise();
      promise.then(this.registerOS(), function (err) {
        console.log(err);
        this.uploadingOS = false;
        return alert("There was an error uploading your file: ", err.message);
      });
    },
    async doUploadOS() {
      if (this.uploadingOS) {
        return;
      }
      this.uploadingOS = true;
      if (this.file) {
        await request
          .get("/SyncKit/Credential", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
            },
          })
          .then((response) => {
            if (this.file.size > 5 * 1024 * 1024) {
              this.uploadAWSmulti(response.data);
            } else {
              this.uploadAWS(response.data);
            }
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      } else {
        await request
          .patch("/SyncKit/OS", this.register, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            this.getStates();
            alert("更新成功");
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
            this.uploadingOS = false;
          });
      }
    },
    async doUploadAPP() {
      if (this.uploadingAPP) {
        return;
      }
      this.uploadingAPP = true;
      if (this.uploadAPP.file) {
        let formData = new FormData();
        formData.append("file", this.uploadAPP.file);
        formData.append("Ver", this.uploadAPP.Ver);
        formData.append("Meta", this.uploadAPP.Meta);
        await request
          .post("/SyncKit/App", formData, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.getStates();
            alert("已成功上傳APP");
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      } else {
        await request
          .patch("/SyncKit/App", this.uploadAPP, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            this.getStates();
            alert("更新成功");
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }

      this.uploadingAPP = false;
    },
    changeTab(mode) {
      this.nowMode = mode;
    },
    get_cookie(name) {
      let match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.synckitManager = new Permission(this.get_cookie("Perm")).hasPermission(
      permFlagDefine.UPsynckitManager
    );
    this.getStates();
  },
};
</script>
<style></style>
