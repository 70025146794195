<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務上線總量"
            type="gradient-green"
            :sub-title="checkHeader.TotalTarget.toString()"
            icon="ni ni-spaceship"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務中斷總量"
            type="gradient-red"
            :sub-title="checkHeader.TotalFail.toString()"
            icon="ni ni-fat-remove"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="上次檢查時間"
            type="gradient-info"
            :sub-title="checkHeader.LastCheckTime"
            icon="ni ni-calendar-grid-58"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="mb-8"></div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-xl-8">
                    <h3 class="mb-0">新增服務</h3>
                  </div>
                  <div class="col-xl-4 text-right">
                    <base-button
                      type="info"
                      icon="ni ni-user-run"
                      v-on:mouseup="save()"
                      >儲存新增</base-button
                    >
                  </div>
                </div>
              </div>
            </template>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="url"
                  label="網址"
                  placeholder="必須輸入"
                  input-classes="form-control-alternative"
                  v-model="model.Address"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  alternative=""
                  label="服務名稱"
                  placeholder="必須輸入"
                  input-classes="form-control-alternative"
                  v-model="model.ServiceName"
                />
              </div>
            </div>
            <div class="row">
              <div class="mr-4 ml-3">
                <span class="form-control-label">是否通知</span>
              </div>
              <div>
                <base-switch :value="model.NeedInfo" v-model="model.NeedInfo" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱1"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[0]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱2"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[1]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱3"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[2]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱4"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[3]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱5"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[4]"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "../components/BaseButton.vue";
import Card from "../components/Card.vue";
import request from "@/utils/request";
export default {
  name: "AddTarget",
  components: { BaseButton, Card },
  data() {
    return {
      model: {
        Address: "",
        ServiceName: "",
        MailTo: [],
        NeedInfo: false,
      },
      checkHeader: {
        TotalFail: 0,
        TotalTarget: 3,
        LastCheckTime: "foo",
      },
    };
  },
  methods: {
    async save() {
      if (!this.model.ServiceName || !this.model.Address) {
        alert("必須提供網址與名稱");
      } else {
        await request
          .post(
            "/AutoCheck/Target",
            {
              ...this.model,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token.access_token,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            alert("新增成功");
            this.$router.push({
              name: "AutoCheck",
            });
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }
    },
  },
  beforeMount() {
    this.checkHeader = this.$store.state.checkHeader;
    if (this.checkHeader === "") {
      this.$router.push({
        name: "AutoCheck",
      });
    }
  },
};
</script>
<style></style>
