let config;

if (process.env.NODE_ENV === "production") {
  config = {
    $api_url: "https://deploy.cvicloud-srv.net/v1",
    $api_new: "https://deploy.cvicloud-srv.net/v2",
    $host_name: "deploy.cvicloud-srv.net",
    $project_folder_name: "",
    $mqtt_port: 32012,
    $diaper_message_timeout_seconds: 40,
    $env: "prod",
  };
} else {
  config = {
    //$api_url: "https://www.cvicloud-srv.net/v1",
    //$host_name: "www.cvicloud-srv.net",
    $api_url: "http://localhost:8888/v1",
    $api_new: "http://localhost:8888/v2",
    $host_name: "localhost:8080",
    $project_folder_name: "",
    $mqtt_port: 32012,
    $diaper_message_timeout_seconds: 40,
    $env: "dev",
  };
}

export { config };
