const permFlagDefine = {
  UPidentifyManager: 1,
  UPstoreManager: 2,
  UPinnerManager: 4,
  UPserviceManager: 8,
  UPdeviceManager: 16,
  UPsynckitManager: 32,
};

let permF = {
  UPidentifyManager: false,
  UPstoreManager: false,
  UPinnerManager: false,
  UPserviceManager: false,
  UPdeviceManager: false,
  UPsynckitManager: false,
};

export { permFlagDefine };

class Permission {
  UPidentifyManager = false;
  UPstoreManager = false;
  UPinnerManager = false;
  UPserviceManager = false;
  UPdeviceManager = false;
  constructor(flagsNum) {
    this.UPidentifyManager =
      (flagsNum & permFlagDefine.UPidentifyManager) > 0 ? true : false;
    this.UPstoreManager =
      (flagsNum & permFlagDefine.UPstoreManager) > 0 ? true : false;
    this.UPinnerManager =
      (flagsNum & permFlagDefine.UPinnerManager) > 0 ? true : false;
    this.UPserviceManager =
      (flagsNum & permFlagDefine.UPserviceManager) > 0 ? true : false;
    this.UPdeviceManager =
      (flagsNum & permFlagDefine.UPdeviceManager) > 0 ? true : false;
    this.UPsynckitManager =
      (flagsNum & permFlagDefine.UPsynckitManager) > 0 ? true : false;
  }
  getPermFlags() {
    permF.UPidentifyManager =
      (this & permFlagDefine.UPidentifyManager) > 0 ? true : false;
    permF.UPstoreManager =
      (this & permFlagDefine.UPstoreManager) > 0 ? true : false;
    permF.UPinnerManager =
      (this & permFlagDefine.UPinnerManager) > 0 ? true : false;
    permF.UPserviceManager =
      (this & permFlagDefine.UPserviceManager) > 0 ? true : false;
    permF.UPdeviceManager =
      (this & permFlagDefine.UPdeviceManager) > 0 ? true : false;
    permF.UPsynckitManager = 
      (this & permFlagDefine.UPsynckitManager) > 0 ? true : false;
    return permF;
  }
  getPermNum() {
    let rtn = 0;
    if (this.UPidentifyManager) {
      rtn = rtn + permFlagDefine.UPidentifyManager;
    }
    if (this.UPstoreManager) {
      rtn = rtn + permFlagDefine.UPstoreManager;
    }
    if (this.UPinnerManager) {
      rtn = rtn + permFlagDefine.UPinnerManager;
    }
    if (this.UPserviceManager) {
      rtn = rtn + permFlagDefine.UPserviceManager;
    }
    if (this.UPdeviceManager) {
      rtn = rtn + permFlagDefine.UPdeviceManager;
    }
    if (this.UPsynckitManager) {
      rtn = rtn + permFlagDefine.UPsynckitManager;
    }
    return rtn;
  }
  hasPermission(perm) {
    return (this.getPermNum() & perm) > 0 ? true : false;
  }
}

export { Permission };
