<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign up with credentials</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative"
              placeholder="Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.display_name"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
              focused
            >
            </base-input>
            <base-input
              formClasses="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>
            <div class="text-muted font-italic">
              <small
                >密碼強度:
                <span
                  class="text-success font-weight-700"
                  v-if="strength == 4 ? true : false"
                  >極好</span
                >
                <span
                  class="text-info font-weight-700"
                  v-else-if="strength == 3 ? true : false"
                  >尚可</span
                >
                <span
                  class="text-warning font-weight-700"
                  v-else-if="strength == 2 ? true : false"
                  >不足</span
                >
                <span class="text-danger font-weight-700" v-else
                  >偏弱</span
                ></small
              >
            </div>
            <small v-if="strength < 3 ? true : false"
              >密碼需要為大小寫、數字與符號組成，且需大於8個字</small
            >

            <!-- <div class="row my-4">
              <div class="col-12">
                <base-checkbox class="custom-control-alternative">
                  <span class="text-muted"
                    >I agree with the <a href="#!">Privacy Policy</a></span
                  >
                </base-checkbox>
              </div>
            </div> -->
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                v-on:mouseup="doRegister()"
                >Create account</base-button
              >
            </div>
            <div class="text-left text-muted mt-4">
              <small
                >This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                and
                <a href="https://policies.google.com/terms">Terms of Service</a>
                apply.</small
              >
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="/" class="text-light">
            <small>Dashboard</small>
          </a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  name: "register",
  data() {
    return {
      myToken: "",
      strength: 0,
      model: {
        display_name: "",
        email: "",
        password: "",
        response: "",
      },
    };
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.calculateSTR(val.password);
      },
    },
  },
  methods: {
    calculateSTR() {
      var str = 0;
      if (this.model.password.length < 8) {
        this.strength = 0;
        return;
      }
      if (/\d/.test(this.model.password)) str++;
      if (/[a-z]/.test(this.model.password)) str++;
      if (/[A-Z]/.test(this.model.password)) str++;
      if (/\W/.test(this.model.password)) str++;
      this.strength = str;
    },
    async doRegister() {
      if (this.model.display_name === "") {
        alert("必須輸入名稱");
        return;
      }
      if (this.model.email === "") {
        alert("必須輸入信箱");
        return;
      }
      if (this.strength < 3) {
        alert("密碼強度太弱");
        return;
      }
      await this.$recaptchaLoaded();
      this.model.response = await this.$recaptcha("register");
      await request
        .post(
          "/user/register",
          { ...this.model },
          {
            headers: {
              Authorization: process.env.VUE_APP_AUTHORIZATIOH,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("註冊成功！請收信並且完成帳號啟動！");
          this.$router.push({
            path: "/login",
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.error) {
            alert(error.response.data.error);
          } else {
            alert(error.response.data);
          }
        });
    },
  },
};
</script>
<style></style>
