<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      src="img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="info" class="mr-4"
                  >iOS</base-button
                >
                <base-button size="sm" type="default" class="float-right">{{
                  model.status
                }}</base-button>
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  >
                    <!-- <div>
                      <span class="heading">1.0.0</span>
                      <span class="description">版本號</span>
                    </div>
                    <div>
                      <span class="heading">2021/07/11</span>
                      <span class="description">上架時間</span>
                    </div>
                    <div>
                      <span class="heading">Jerry</span>
                      <span class="description">上次更新人員</span>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ model.appName
                  }}<!--<span class="font-weight-light">, 27</span>-->
                </h3>
                <div class="h5 font-weight-300">
                  <em class="ni location_pin mr-2"></em>版本號{{
                    model.version
                  }}
                </div>
                <hr class="my-4" />
                <div class="h5 mt-4">
                  <em class="ni business_briefcase-24 mr-2"></em>上次更新時間 -
                  {{ model.lastUpdate }}
                </div>
                <div>
                  <em class="ni education_hat mr-2"></em>由
                  {{ model.editor }} 更新
                </div>
                <!-- <p>
                  Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick
                  Murphy — writes, performs and records all of his own music.
                </p>
                <a href="#">Show more</a> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ model.folderName }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      class="btn btn-sm btn-danger"
                      v-on:click="deleteApp(model.folderName)"
                      >移除APP</a
                    >
                    <a
                      class="btn btn-sm btn-primary"
                      v-on:click="
                        updateAppStatus(
                          model.folderName,
                          model.appName,
                          model.version,
                          model.status,
                          model.editor
                        )
                      "
                      >儲存變更</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">上架資訊</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="APP名稱"
                      :placeholder="model.appName"
                      input-classes="form-control-alternative"
                      v-model="model.appName"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="更新人員"
                      :placeholder="model.editor"
                      input-classes="form-control-alternative"
                      v-model="model.editor"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="版本號"
                      :placeholder="model.version"
                      input-classes="form-control-alternative"
                      v-model="model.version"
                    />
                  </div>
                  <div class="col-lg-6">
                    <!-- <base-input :label="$t('dashboard.dropdownLists.floorsListTitle')"> -->
                    <base-input label="請選擇上架狀態">
                      <select class="form-control" v-model="model.status">
                        <option>已上架</option>
                        <option>停售</option>
                        <option>審核中</option>
                        <option>開發中</option>
                      </select>
                      <!-- <select
                        @change="onFloorChange($event)"
                        v-model="selectedFloor"
                        class="form-control"
                      >
                        <option v-for="floor in floors" v-bind:key="floor.Value">
                          {{ floor.Text }}
                        </option>
                      </select> -->
                    </base-input>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  name: "edit-google-store",
  data() {
    return {
      model: {
        appName: "",
        version: "",
        status: "",
        editor: "",
        folderName: "",
        lastUpdate: "",
      },
    };
  },
  methods: {
    async updateAppStatus(folderName, appName, version, status, editor) {
      await request
        .patch(
          "/AppStatus/Android/" + folderName,
          {
            appName: appName,
            version: version,
            status: status,
            editor: editor,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("已成功更新上架資訊。");
          location.href = this.$config.$project_folder_name + "/dashboard";
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    },
    async deleteApp(folderName) {
      if (confirm("確定要移除 " + this.model.appName + " 嗎？")) {
        await request
          .delete("/AppStatus/Android/" + folderName, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            alert("已成功移除APP。");
            location.href = this.$config.$project_folder_name + "/dashboard";
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }
    },
  },
  beforeMount() {
    this.model.appName = this.$route.query.appName;
    this.model.version = this.$route.query.version;
    this.model.status = this.$route.query.status;
    this.model.editor = this.$route.query.editor;
    this.model.folderName = this.$route.query.folderName;
    this.model.lastUpdate = this.$route.query.lastUpdate;
  },
};
</script>
<style></style>
