<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <!-- <div class="form-group mb-0">
        <base-input
          placeholder="Search"
          class="input-group-alternative"
          alternative=""
          addon-right-icon="fas fa-search"
        >
        </base-input>
      </div> -->
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="img/theme/team-4-800x800.jpg"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{ user }}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <div v-if="logined">
            <div v-on:click="toEditUsersPage">
              <router-link to="/edit-users" class="dropdown-item">
                <em class="ni ni-settings-gear-65"></em>
                <span>權限管理</span>
              </router-link>
            </div>
            <div class="dropdown-divider"></div>
            <router-link to="/login" class="dropdown-item" v-on:click="logout">
              <em class="ni ni-user-run"></em>
              <span>登出</span>
            </router-link>
          </div>
          <div v-else>
            <router-link to="/login" class="dropdown-item">
              <em class="ni ni-user-run"></em>
              <span>登入</span>
            </router-link>
          </div>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      logined: false,
      user: "Guest",
    };
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.commit("logout");
      document.cookie =
        "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
      document.cookie =
        "Perm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
    },
    get_cookie(name) {
      let match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
    toEditUsersPage() {
      location.href = this.$config.$project_folder_name + "/edit-users";
    },
  },
  beforeMount() {
    this.user =
      this.get_cookie("User") == null ? "Guest" : this.get_cookie("User");
    this.logined = this.get_cookie("User") == null ? false : true;
  },
};
</script>
