<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">企業版 iOS APP列表</h3>
        </div>
        <div class="col text-right">
          <a
            class="btn btn-sm btn-success"
            v-on:click="createAppStatus()"
            v-if="logined"
            >新增APP</a
          >
          <a class="btn btn-sm btn-primary" v-on:click="getInnerIosList()"
            >重新整理</a
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template v-slot:columns>
          <th>APP名稱</th>
          <th>最新版號</th>
          <th>下載連結</th>
          <th v-if="logined">編輯</th>
          <th v-if="logined">上傳</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            {{ row.item.appName }}
          </th>
          <td>
            {{ row.item.version }}
          </td>
          <td>
            <!-- <div class="d-flex align-items-center">
              <span class="mr-2">{{ row.item.progress }}%</span>
              <base-progress
                :type="row.item.progressType"
                class="pt-0"
                :show-percentage="false"
                :value="row.item.progress"
              />
            </div> -->
            <base-button
              type="primary"
              size="sm"
              v-on:mousedown="copyAppLink(row.item.link)"
              >複製連結</base-button
            >
            <!-- <a v-on:click="copyAppLink(row.item.link)">複製連結</a> -->
            <!-- <a :href="`${row.item.link}`" target="blank">下載</a> -->
          </td>
          <td v-if="logined">
            <base-button
              type="primary"
              size="sm"
              v-on:click="editAppStatus(row.item)"
              >編輯</base-button
            >
          </td>
          <td v-if="logined">
            <base-button
              type="primary"
              size="sm"
              v-on:click="uploadAppStatus(row.item)"
              >上傳</base-button
            >
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import { Permission, permFlagDefine } from "@/utils/permission";
export default {
  name: "inner-ios-table",
  data() {
    return {
      tableData: [
        {
          appName: "",
          editor: "",
          folderName: "",
          lastUpdate: "",
          timestamp: undefined,
          version: "",
          link: "",
        },
      ],
      user: "",
      logined: false,
    };
  },
  methods: {
    copyAppLink(text) {
      if (!navigator.clipboard) {
        // 用於舊的瀏覽器
        if (window.clipboardData && window.clipboardData.setData) {
          // IE specific code path to prevent textarea being shown while dialog is visible.
          // eslint-disable-next-line no-undef
          return clipboardData.setData("Text", text);
        } else if (
          document.queryCommandSupported &&
          document.queryCommandSupported("copy")
        ) {
          var textarea = document.createElement("textarea");
          textarea.textContent = text;
          textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
          document.body.appendChild(textarea);
          textarea.select();
          try {
            return document.execCommand("copy"); // Security exception may be thrown by some browsers.
          } catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return false;
          } finally {
            document.body.removeChild(textarea);
            alert("複製APP下載頁面連結成功!");
            // eslint-disable-next-line no-unsafe-finally
          }
        }
      } else {
        // 新的瀏覽器支援此命令，而IE不行
        navigator.clipboard
          .writeText(text)
          .then(function () {
            alert("複製APP下載頁面連結成功!"); // success
          })
          .catch(function () {
            alert("複製下載頁面連結失敗：\n" + text); // 失敗則直接顯示連結在對話框內
          });
      }
    },
    createAppStatus() {
      location.href =
        this.$config.$project_folder_name +
        "/create-inner-ios?editor=" +
        this.user;
    },
    editAppStatus(appdata) {
      location.href = String.format(
        this.$config.$project_folder_name +
          "/edit-inner-ios?appName={0}&folderName={1}&version={2}&link={3}&editor={4}&lastUpdate={5}",
        appdata.appName,
        appdata.folderName,
        appdata.version,
        appdata.link,
        appdata.editor,
        appdata.lastUpdate
      );
    },
    uploadAppStatus(appdata) {
      location.href = String.format(
        this.$config.$project_folder_name +
          "/upload-inner-ios?appName={0}&folderName={1}&version={2}&link={3}&editor={4}&lastUpdate={5}",
        appdata.appName,
        appdata.folderName,
        appdata.version,
        appdata.link,
        appdata.editor,
        appdata.lastUpdate
      );
    },
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
    getInnerIosList() {
      var that = this;
      this.$axios
        .get(this.$config.$api_url + "/get/InnerIosList", {})
        .then((innerIosList) => {
          that.tableData = innerIosList.data;
          that.tableData.sort(function (a, b) {
            return b["timestamp"] - a["timestamp"];
          });
        });
    },
  },
  beforeMount() {
    this.user =
      this.get_cookie("User") == null ? "Guest" : this.get_cookie("User");
    this.logined = new Permission(this.get_cookie("Perm")).hasPermission(
      permFlagDefine.UPinnerManager
    );
    this.getInnerIosList();
    if (!String.format) {
      String.format = function (format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != "undefined" ? args[number] : match;
        });
      };
    }
  },
};
</script>
<style></style>
