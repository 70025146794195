/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import "element-plus/lib/theme-chalk/index.css";
import { config } from "./config";
import axios from "axios";
import store from "./store";
import { VueReCaptcha } from "vue-recaptcha-v3";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.config.globalProperties.$config = config;
//axios.defaults.withCredentials = true;
appInstance.config.globalProperties.$axios = axios;
appInstance.use(store);
appInstance.use(VueReCaptcha, {
  siteKey: "6LeCrb8gAAAAAMpWP6BabWb0pjr7DC-oW13FlKLt",
  loaderOptions: {
    autoHideBadge: true,
    useRecaptchaNet: true,
  },
});
// eslint-disable-next-line prettier/prettier
appInstance.mount("#app");