<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      src="img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between">
                <base-button size="sm" type="info" class="mr-4"
                  >iOS</base-button
                >
              </div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="card-profile-stats d-flex justify-content-center mt-md-5"
                  ></div>
                </div>
              </div>
              <div class="text-center">
                <h3>
                  {{ model.appName
                  }}<!--<span class="font-weight-light">, 27</span>-->
                </h3>
                <div class="h5 font-weight-300">
                  <em class="ni location_pin mr-2"></em>版本號{{
                    model.version
                  }}
                </div>
                <hr class="my-4" />
                <div class="h5 mt-4">
                  <em class="ni business_briefcase-24 mr-2"></em>上次更新時間 -
                  {{ model.lastUpdate }}
                </div>
                <div>
                  <em class="ni education_hat mr-2"></em>由
                  {{ model.editor }} 更新
                </div>
                <!-- <p>
                  Ryan — the name taken by Melbourne-raised, Brooklyn-based Nick
                  Murphy — writes, performs and records all of his own music.
                </p>
                <a href="#">Show more</a> -->
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ model.folderName }}</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      class="btn btn-sm btn-primary"
                      v-on:click="upload"
                      v-if="isReady"
                      >上傳</a
                    >
                  </div>
                </div>
              </div>
            </template>
            <form>
              <h6 class="heading-small text-muted mb-4">內部APP資訊</h6>
              <div class="pl-lg-4" v-if="isReady">
                <label class="btn btn-gray">
                  <input
                    type="file"
                    ref="file"
                    @change="selectFile"
                    accept=".zip"
                  />
                </label>
              </div>
              <waiting-response
                v-else
                msg="上傳中，請勿離開頁面"
              ></waiting-response>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import WaitingResponse from "@/components/WaitingResponse.vue";
export default {
  name: "upload-inner-android",
  components: {
    WaitingResponse,
  },
  data() {
    return {
      isReady: false,
      model: {
        appName: "",
        version: "",
        link: "",
        editor: "",
        folderName: "",
        lastUpdate: "",
        selectedFiles: undefined,
        currentFile: undefined,
        isSelect: false,
        nowUser: "",
      },
    };
  },
  methods: {
    upload() {
      if (this.model.selectedFiles == undefined) {
        console.log("Undefined");
        alert("請選擇檔案");
      } else {
        this.isReady = false;
        this.doUpload();
      }
    },
    selectFile() {
      this.model.isSelect = true;
      this.model.selectedFiles = this.$refs.file.files;
    },
    async doUpload() {
      let formData = new FormData();
      formData.append("file", this.model.selectedFiles.item(0));
      await request
        .post(
          "/InnerApp/upload/Android/" +
            this.model.folderName +
            "/" +
            this.get_cookie("User"),
          formData,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          alert("已成功新增上架資訊。");
          location.href = this.$config.$project_folder_name + "/dashboard";
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
          this.isReady = true;
        });
    },
    get_cookie(name) {
      let match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.model.appName = this.$route.query.appName;
    this.model.version = this.$route.query.version;
    this.model.link = this.$route.query.link;
    this.model.editor = this.$route.query.editor;
    this.model.folderName = this.$route.query.folderName;
    this.model.lastUpdate = this.$route.query.lastUpdate;
    this.isReady = true;
  },
};
</script>
