<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!-- <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div> -->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>請選擇您的身份，<br />或直接以一般用戶進入平台</small>
          </div>
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              type="email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <!-- <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox> -->
            <div class="btn-wrapper text-center">
              <a
                href="/dashboard"
                class="btn btn-primary btn-icon"
                v-on:click="logout"
              >
                <span class="btn-inner--text">以一般用戶進入</span>
              </a>
              <!--  <a
                href="/dashboard"
                class="btn btn-primary btn-icon"
                v-on:click="login"
              >
                <span class="btn-inner--text">以技術人員登入</span> 
              </a> -->
              <base-button type="default" @mouseup="login">登入</base-button>
            </div>
            <div class="text-left text-muted mt-4">
              <small
                >This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a>
                and
                <a href="https://policies.google.com/terms">Terms of Service</a>
                apply.</small
              >
            </div>
            <!-- <div class="text-center">
              <base-button type="primary" class="my-4">技術人員登入</base-button>
            </div>
            <div class="text-center">
              <base-button type="primary" class="my-4">一般用戶</base-button>
            </div> -->
          </form>
        </div>
      </div>
      <!-- <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import BaseButton from "../components/BaseButton.vue";
import request from "@/utils/request";
export default {
  components: { BaseButton },
  name: "login",
  data() {
    return {
      model: {
        email: process.env.VUE_APP_USER,
        password: process.env.VUE_APP_PASSWORD,
        grant_type: "password",
        response: "",
      },
    };
  },
  methods: {
    async login() {
      await this.$recaptchaLoaded();
      this.model.response = await this.$recaptcha("login");
      try {
        let response = await request.post(
          "/user/token",
          { ...this.model },
          {
            headers: {
              Authorization: process.env.VUE_APP_AUTHORIZATIOH,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status == 200) {
          localStorage.setItem("token", JSON.stringify(response.data));
          localStorage.setItem(
            "info",
            JSON.stringify({
              email: this.model.email,
              preferOS: "",
            })
          );
          this.$store.state.token = response.data;
          /* this.$store.state.info = {
            email: this.model.email,
            preferOS: "",
          }; */
          this.$store.commit("setInfo", {
            email: this.model.email,
            preferOS: "",
          });
          let d = new Date();
          // Set Cookie expires in 1 Hours
          d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
          let expires = "expires=" + d.toUTCString();
          //document.cookie = "User=" + this.model.user + ";" + expires + ";path=/";
          document.cookie =
            "User=" +
            this.$store.state.token.name +
            ";" +
            expires +
            "; SameSite=Strict; path=/";
          document.cookie =
            "Perm=" +
            response.data.permission +
            ";" +
            expires +
            "; SameSite=Strict; path=/";
          //this.goDashboard();
          this.$router.push({
            path: "/dashboard",
          });
        } else {
          console.log("Fail? I don't know why here?");
        }
      } catch (error) {
        if (!error.response) {
          alert("伺服器連線失敗！");
        } else {
          console.log(error.response.data);
          if (error.response.data.error) {
            alert(error.response.data.error);
          } else {
            alert(error.response.data);
          }
        }
      }
    },
    logout() {
      this.$store.commit("logout");
      document.cookie =
        "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
      document.cookie =
        "Perm=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
    },
  },
  /* mounted() {
    const recaptcha = this.$recaptchaInstance;
    recaptcha.showBadge();
  },
  unmounted() {
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  }, */
  /* beforeMount() {
    this.getAdminUsers();
  }, */
};
</script>
<style></style>
