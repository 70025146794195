<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務上線總量"
            type="gradient-green"
            :sub-title="Results.TotalTarget.toString()"
            icon="ni ni-spaceship"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務中斷總量"
            type="gradient-red"
            :sub-title="Results.TotalFail.toString()"
            icon="ni ni-fat-remove"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="上次檢查時間"
            type="gradient-info"
            :sub-title="Results.LastCheckTime"
            icon="ni ni-calendar-grid-58"
            class="mb-4 mb-xl-0"
          >
            <!-- <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.IosLatestLaunchEditor }} 更新於
              </span>
              <span class="text-nowrap">{{ topStatus.IosLatestLaunchAt }}</span>
            </template> -->
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="mb-8"></div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-xl-4">
                    <h3 class="mb-0">CviCloud 線上服務</h3>
                  </div>
                  <div class="mt-2 col-xl-4 text-right">
                    <base-button
                      type="info"
                      icon="ni ni-user-run"
                      v-on:mouseup="startTest()"
                      v-if="isReady"
                      >開始檢查</base-button
                    >
                    <base-button
                      type="success"
                      icon="ni ni-user-run"
                      v-on:mouseup="getResults()"
                      v-else
                      >更新狀態</base-button
                    >
                  </div>
                  <div class="mt-2 col-xl-4 text-right" v-if="serviceManager">
                    <base-button
                      type="primary"
                      icon="ni ni-fat-add"
                      v-on:mouseup="addTarget()"
                      >新增</base-button
                    >
                    <base-button
                      type="default"
                      icon="ni ni-palette"
                      :disabled="disableBtn"
                      v-on:mouseup="editTarget()"
                      >編輯</base-button
                    >
                    <base-button
                      type="danger"
                      icon="ni ni-fat-delete"
                      :disabled="disableBtn"
                      v-on:mouseup="deleteTarget()"
                      >刪除</base-button
                    >
                  </div>
                </div>
              </div>
            </template>
            <tabs fill class="flex-column flex-md-row">
              <card shadow>
                <tab-pane title="目前服務狀態" @click="changeTab('state')">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="table-responsive">
                        <base-table
                          thead-classes="thead-light"
                          :data="Results.Result"
                        >
                          <template v-slot:columns>
                            <th>服務名稱</th>
                            <th>上線狀態</th>
                            <th>反應時間</th>
                            <th>服務網址</th>
                          </template>
                          <template v-slot:default="row">
                            <th scope="row">
                              {{ row.item.Name }}
                            </th>
                            <td>
                              <h5 style="color: #2dce89" v-if="row.item.IsPass">
                                Pass
                              </h5>
                              <h5 style="color: red" v-else>Fail</h5>
                            </td>
                            <td>
                              {{ row.item.ResponseTime }}
                            </td>
                            <td>
                              {{ row.item.Address }}
                            </td>
                          </template>
                        </base-table>
                      </div>
                    </div>
                  </div>
                </tab-pane>
                <tab-pane title="服務清單" @click="changeTab('target')">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input :label="listHeader">
                        <div class="table-responsive">
                          <base-table
                            thead-classes="thead-light"
                            :data="Targets"
                          >
                            <template v-slot:columns>
                              <th v-if="serviceManager">選擇</th>
                              <th>服務名稱</th>
                              <th>是否通報</th>
                              <th>服務網址</th>
                            </template>
                            <template v-slot:default="row">
                              <td v-if="serviceManager">
                                <input
                                  type="radio"
                                  v-model="selected"
                                  :value="row.item"
                                />
                              </td>
                              <th scope="row">
                                {{ row.item.ServiceName }}
                              </th>
                              <td>
                                {{ row.item.NeedInfo }}
                              </td>
                              <td>
                                {{ row.item.Address }}
                              </td>
                            </template>
                          </base-table>
                        </div>
                      </base-input>
                    </div>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import { Permission, permFlagDefine } from "@/utils/permission";
import BaseButton from "../components/BaseButton.vue";
import Card from "../components/Card.vue";
import request from "@/utils/request";
export default {
  name: "AutoCheck",
  components: { BaseButton, Card },
  data() {
    return {
      listHeader: "服務清單",
      serviceManager: false,
      disableBtn: true,
      isReady: false,
      nowMode: "",
      selected: "",
      Results: {
        LastCheckTime: "2022-06-01 11:44:56 UTC+8",
        PreTestResult: [
          {
            Name: "Opro9 Web",
            Address: "https://www.opro9.com/",
            IsPass: true,
            ResponseTime: 1924,
          },
        ],
        TestErr: "",
        TotalTarget: 1,
        TotalFail: 0,
      },
      Targets: [
        {
          Address: "https://www.opro9.com/",
          ServiceName: "Opro9 Web",
          MailTo: ["david.chou@opro9.com"],
          NeedInfo: true,
        },
      ],
    };
  },
  methods: {
    async startTest() {
      await request
        .get("/AutoCheck/Start", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then(() => {
          this.isReady = false;
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
      this.getResults();
    },
    addTarget() {
      this.$store.state.checkHeader = {
        TotalFail: this.Results.TotalFail,
        TotalTarget: this.Results.TotalTarget,
        LastCheckTime: this.Results.LastCheckTime,
      };
      this.$router.push({
        name: "AddTarget",
      });
    },
    editTarget() {
      if (this.selected) {
        this.$store.state.editedTarget = this.selected;
        this.$store.state.checkHeader = {
          TotalFail: this.Results.TotalFail,
          TotalTarget: this.Results.TotalTarget,
          LastCheckTime: this.Results.LastCheckTime,
        };
        this.$router.push({
          name: "EditTarget",
        });
      }
    },
    async deleteTarget() {
      if (this.selected) {
        if (
          confirm(
            "確認刪除服務：" +
              this.selected.ServiceName +
              "\n位於：" +
              this.selected.Address
          )
        ) {
          await request
            .delete("/AutoCheck/Target", {
              headers: {
                Authorization: "Bearer " + this.$store.state.token.access_token,
                "Content-Type": "application/json",
              },
              data: {
                Address: this.selected.Address,
              },
            })
            .then(() => {
              alert("移除成功");
              location.href = this.$config.$project_folder_name + "/auto-check";
            })
            .catch((error) => {
              console.log(error);
              alert(error.response.data.error);
            });
        }
      } else {
        alert("請選擇服務");
      }
    },
    changeTab(mode) {
      this.nowMode = mode;
      if (mode == "target") {
        this.disableBtn = false;
      }
    },
    async getResults() {
      await request
        .get("/AutoCheck/Stage", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then((response) => {
          if (response.data.Stage === "Ready") {
            this.isReady = true;
          } else {
            this.isReady = false;
          }
        })
        .catch(() => {
          this.$router.push({
            path: "/dashboard",
          });
        });
      await request
        .get("/AutoCheck/Result", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then((response) => {
          this.Results = response.data.Result;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTargets() {
      await request
        .get("/AutoCheck/Target", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then((response) => {
          this.Targets = response.data.Target;
        })
        .catch(() => {
          this.$router.push({
            path: "/dashboard",
          });
        });
    },
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.getResults();
    this.getTargets();
    this.serviceManager = new Permission(this.get_cookie("Perm")).hasPermission(
      permFlagDefine.UPserviceManager
    );
    if (this.serviceManager) {
      this.listHeader = "請選擇待編輯或移除的服務";
    }
  },
};
</script>
<style></style>
