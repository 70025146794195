import axios from "axios";
import store from "@/store/index";
//import { cfg } from "../config.js";

const request = axios.create({
  baseURL: process.env.VUE_APP_API_NEW,
  headers: {
    "Content-Type": "application/json",
  },
  responseEncoding: "utf8",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
});
//
let whiteList = ["/user/token"];
request.interceptors.request.use(
  (config) => {
    let white = whiteList.some((item) => config.url.startsWith(item));
    if (white) {
      return config;
    }
    if (store.state.token && store.state.token.access_token) {
      config.headers.Authorization = `Bearer ${store.state.token.access_token}`;
    } else {
      config.headers.Authorization = process.env.VUE_APP_AUTHORIZATIOH;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("Request Error");
    const originalRequest = error.config;
    if (error.response.status === 401) {
      //console.log("Run Refresh");
      const refresh_token = store.state.token.refresh_token || "";
      if (refresh_token.length === 0) {
        console.log("No Refresh Token");
        localStorage.removeItem("token");
        store.state.token = "";
        localStorage.removeItem("info");
        store.state.info = "";
        document.cookie =
          "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
        window.location.href = "/";
        return Promise.reject("");
      }
      await axios
        .post(
          process.env.VUE_APP_API_NEW + "/user/token",
          {
            refresh_token: refresh_token,
            grant_type: "refresh_token",
          },
          {
            headers: {
              Authorization: process.env.VUE_APP_AUTHORIZATIOH,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let token = {
            ...response.data,
          };
          localStorage.setItem("token", JSON.stringify(token));
          store.state.token = token;
          const auth = `Bearer ${token.access_token}`;
          request.defaults.headers["Authorization"] = auth;
          originalRequest.headers["Authorization"] = auth;
          //console.log("Refresh OK");
          window.location.reload();
          return request(originalRequest);
          /* await axios(originalRequest)
            .then((res) => {
              console.log("Success in 2nd send");
              console.log(res);
              return res;
            })
            .catch((err) => {
              console.log("Refresh Error in Resend" + err);
              return Promise.reject(error);
            }); */
        })
        .catch((err) => {
          console.log("Refresh Error" + err);
          localStorage.removeItem("token");
          store.state.token = "";
          localStorage.removeItem("info");
          store.state.info = "";
          document.cookie =
            "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict; path=/;";
          window.location.href = "/";
          return Promise.reject(error);
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export default request;
