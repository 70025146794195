<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">編輯表單</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a
                      class="btn btn-sm btn-warning"
                      v-on:click="editPage = false"
                      v-if="editPage"
                      >取消</a
                    >
                    <a class="btn btn-sm btn-primary" v-on:click="updateUsers()"
                      >儲存變更</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <tabs fill class="flex-column flex-md-row">
              <card shadow>
                <tab-pane title="更新個人資訊" v-on:mouseup="changeTab('Edit')">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="使用者"
                        :placeholder="model.nowUser"
                        input-classes="form-control-alternative"
                        v-model="model.nowUser"
                      />
                    </div>
                  </div>
                </tab-pane>
                <tab-pane
                  title="新增技術人員"
                  v-on:click="changeTab('Create')"
                  v-if="identityPerm"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="新用戶名稱"
                        placeholder="請輸入英文用戶名稱"
                        input-classes="form-control-alternative"
                        v-model="model.newUser"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        type="email"
                        label="新用戶信箱"
                        placeholder="請輸入用戶信箱"
                        input-classes="form-control-alternative"
                        v-model="model.newEmail"
                      />
                    </div>
                  </div>
                  <h5>權限設定</h5>
                  <div class="row">
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditUsers"
                        v-model="NewPerm.UPidentifyManager"
                      />
                      <label for="EditUsers" class="ml-2"
                        >可以新增刪除編輯用戶</label
                      >
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditStore"
                        v-model="NewPerm.UPstoreManager"
                      />
                      <label for="EditStore" class="ml-2"
                        >可以新增刪除編輯商城資訊</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditInner"
                        v-model="NewPerm.UPinnerManager"
                      />
                      <label for="EditInner" class="ml-2"
                        >可以新增刪除編輯內部APP</label
                      >
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditService"
                        v-model="NewPerm.UPserviceManager"
                      />
                      <label for="EditService" class="ml-2"
                        >可以新增刪除服務資訊</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditDevices"
                        v-model="NewPerm.UPdeviceManager"
                      />
                      <label for="EditDevices" class="ml-2"
                        >可以批次新增裝置資訊</label
                      >
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="checkbox"
                        id="EditDevices"
                        v-model="NewPerm.UPsynckitManager"
                      />
                      <label for="EditDevices" class="ml-2"
                        >可以管理杏澤OTA服務</label
                      >
                    </div>
                  </div>
                  <div class="text-right">
                    <a class="btn btn-sm btn-primary" href="/register"
                      >註冊頁面</a
                    >
                  </div>
                </tab-pane>
                <tab-pane
                  title="編輯或移除技術人員"
                  v-on:click="changeTab('Remove')"
                  v-if="identityPerm"
                >
                  <div v-if="editPage">
                    <div class="col-lg-12">
                      <label class="form-control-label">
                        使用者： {{ editedProfile.email }}</label
                      >
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="新用戶名稱"
                        :placeholder="editedProfile.name"
                        input-classes="form-control-alternative"
                        v-model="editedProfile.name"
                      />
                    </div>
                    <h5>權限設定</h5>
                    <div class="row">
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditUsers"
                          v-model="editPerm.UPidentifyManager"
                        />
                        <label for="EditUsers" class="ml-2"
                          >可以新增刪除編輯用戶</label
                        >
                      </div>
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditStore"
                          v-model="editPerm.UPstoreManager"
                        />
                        <label for="EditStore" class="ml-2"
                          >可以新增刪除編輯商城資訊</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditInner"
                          v-model="editPerm.UPinnerManager"
                        />
                        <label for="EditInner" class="ml-2"
                          >可以新增刪除編輯內部APP</label
                        >
                      </div>
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditService"
                          v-model="editPerm.UPserviceManager"
                        />
                        <label for="EditService" class="ml-2"
                          >可以新增刪除服務資訊</label
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditDevices"
                          v-model="editPerm.UPdeviceManager"
                        />
                        <label for="EditDevices" class="ml-2"
                          >可以批次新增裝置資訊</label
                        >
                      </div>
                      <div class="col-lg-6">
                        <input
                          type="checkbox"
                          id="EditService"
                          v-model="editPerm.UPsynckitManager"
                        />
                        <label for="EditService" class="ml-2"
                          >可以管理杏澤OTA服務</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-lg-12">
                      <base-input label="請編輯或是選擇待移除的技術人員">
                        <div class="table-responsive">
                          <base-table
                            thead-classes="thead-light"
                            :data="model.users"
                          >
                            <template v-slot:columns>
                              <th>名稱</th>
                              <th>信箱</th>
                              <th>編輯</th>
                              <th>刪除</th>
                            </template>
                            <template v-slot:default="row">
                              <th scope="row">
                                {{ row.item.name }}
                              </th>
                              <td>
                                {{ row.item.email }}
                              </td>
                              <td>
                                <base-button
                                  type="primary"
                                  size="sm"
                                  v-on:mouseup="ToeditTheUser(row.item)"
                                  >編輯</base-button
                                >
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  v-model="model.removeUser"
                                  :value="row.item"
                                />
                              </td>
                            </template>
                          </base-table>
                        </div>
                      </base-input>
                    </div>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
import { Permission, permFlagDefine } from "@/utils/permission";
export default {
  name: "edit-users",
  data() {
    return {
      editPage: false,
      identityPerm: false,
      NewPerm: undefined,
      editPerm: undefined,
      editedProfile: {
        name: "test",
        email: "test@123.456",
        permission: 5,
      },
      model: {
        nowMode: "",
        nowUser: "",
        nowUserBeforeEdit: "",
        users: [
          {
            name: "David",
            email: "david.chou@opro9.com",
            permission: 31,
          },
          {
            name: "Kevin",
            email: "kevin.wei@opro9.com",
            permission: 2,
          },
        ],
        newUser: "",
        newEmail: "",
        newPermission: 0,
        removeUser: undefined,
        nowEmail: "",
      },
    };
  },
  methods: {
    changeTab(mode) {
      this.model.nowMode = mode;
    },
    updateUsers() {
      console.log(this.model.nowMode);
      switch (this.model.nowMode) {
        case "Edit":
          this.editUser();
          break;
        case "Remove":
          if (this.editPage) {
            this.editOtherUser();
          } else {
            this.removeUser();
          }
          break;
        case "Create":
          this.createUser();
          break;
      }
    },
    ToeditTheUser(user) {
      this.editPerm = new Permission(user.permission);
      this.editedProfile.email = user.email;
      this.editedProfile.name = user.name;
      this.editedProfile.permission = user.permission;
      this.editPage = true;
    },
    async editOtherUser() {
      console.log("edit");
      this.editedProfile.permission = this.editPerm.getPermNum();
      await request
        .patch(
          "/user/permission",
          {
            ...this.editedProfile,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("變更成功");
          if (this.model.nowEmail === this.editedProfile.email) {
            let d = new Date();
            // Set Cookie expires in 1 Hours
            d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie =
              "Perm=" +
              this.editedProfile.permission +
              ";" +
              expires +
              "; SameSite=Strict; path=/";
            this.model.nowUser = this.editedProfile.name;
            this.changeLoginNameCookie();
            this.$store.state.info.name = this.model.nowUser;
            localStorage.setItem(
              "info",
              JSON.stringify(this.$store.state.info)
            );
          }
          this.editPage = false;
          this.getAdminUsers();
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    },
    async removeUser() {
      if (this.model.removeUser.email == this.$store.state.info.email) {
        alert("無法移除自己！");
      } else if (
        confirm("確定要移除 " + this.model.removeUser.email + " 嗎？")
      ) {
        await request
          .delete("/user", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
            data: this.model.removeUser,
          })
          .then(() => {
            alert("移除成功");
            location.href = this.$config.$project_folder_name + "/dashboard";
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }
    },
    async createUser() {
      if (this.model.newUser === "") {
        alert("請輸入新用戶名稱");
      } else if (this.model.newEmail === "") {
        alert("請輸入新用戶信箱");
      } else {
        await request
          .post(
            "/user",
            {
              name: this.model.newUser,
              email: this.model.newEmail,
              permission: this.NewPerm.getPermNum(),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token.access_token,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            alert("新增成功");
            this.changeLoginNameCookie();
            this.$store.state.info.name = this.newUser;
            localStorage.setItem(
              "info",
              JSON.stringify(this.$store.state.info)
            );
            location.href = this.$config.$project_folder_name + "/dashboard";
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }
    },
    async editUser() {
      await request
        .patch(
          "/user",
          {
            name: this.model.nowUser,
            email: this.$store.state.info.email,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("變更成功");
          this.changeLoginNameCookie();
          this.$store.state.info.name = this.model.newUser;
          localStorage.setItem("info", JSON.stringify(this.$store.state.info));
          location.href = this.$config.$project_folder_name + "/dashboard";
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    },
    changeLoginNameCookie() {
      let d = new Date();
      d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie =
        "User=" +
        this.model.nowUser +
        ";" +
        expires +
        "; SameSite=Strict; path=/";
    },
    async getAdminUsers() {
      await request
        .get("/user", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then((adminUsers) => {
          this.model.users = adminUsers.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.getAdminUsers();
    this.model.nowUser = this.get_cookie("User");
    this.model.nowUserBeforeEdit = this.get_cookie("User");
    this.model.nowEmail = this.$store.state.info.email;
    let userPerm = new Permission(this.get_cookie("Perm"));
    this.identityPerm = userPerm.hasPermission(
      permFlagDefine.UPidentifyManager
    );
    this.NewPerm = new Permission(0);
  },
};
</script>
<style></style>
