<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
    </base-header>
    <div class="mb-8"></div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-xl-6">
                    <h3 class="mb-0">CviCloud IoT 批次新增產品</h3>
                  </div>
                  <div class="col-xl-6 text-right">
                    <base-button
                      type="success"
                      icon="ni ni-user-run"
                      v-on:mouseup="getResults()"
                      >更新狀態</base-button
                    >
                  </div>
                </div>
              </div>
            </template>
            <tabs fill class="flex-column flex-md-row">
              <card shadow>
                <tab-pane title="產品清單下載">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="table-responsive">
                        <base-table thead-classes="thead-light" :data="Results">
                          <template v-slot:columns>
                            <th>上傳者</th>
                            <th>建立時間</th>
                            <th>下載連結</th>
                            <th>刪除</th>
                          </template>
                          <template v-slot:default="row">
                            <th scope="row">
                              {{ row.item.Creator }}
                            </th>
                            <td>
                              {{ row.item.CreateTime }}
                            </td>
                            <td>
                              <a
                                :href="row.item.FilePath"
                                download
                                class="btn-sm btn-success btn-icon"
                                size="sm"
                                v-if="row.item.IsReady && !row.item.IsError"
                              >
                                下載
                              </a>
                              <h5
                                style="color: red"
                                v-else-if="row.item.IsError"
                              >
                                Fail
                              </h5>
                              <h5 style="color: #2dce89" v-else>排程處理中</h5>
                            </td>
                            <td>
                              <base-button
                                type="danger"
                                size="sm"
                                v-on:mouseup="removeLink(row.item)"
                                >刪除</base-button
                              >
                            </td>
                          </template>
                        </base-table>
                      </div>
                    </div>
                  </div>
                </tab-pane>
                <tab-pane title="上傳產品資料">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          label="序號前綴"
                          placeholder="前綴xxxx"
                          input-classes="form-control-alternative"
                          v-model="model.SNprefix"
                        />
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          label="產品名稱前綴"
                          placeholder="前綴xxxx"
                          input-classes="form-control-alternative"
                          v-model="model.NamePrefix"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          label="韌體ID"
                          placeholder="於CviCloud IoT上之ID編號"
                          input-classes="form-control-alternative"
                          v-model="model.FirmwareID"
                        />
                      </div>
                      <div class="col-lg-6">
                        <label class="form-control-label">產品定義檔</label>
                        <form>
                          <input
                            type="file"
                            ref="file"
                            class="form-control"
                            @change="selectFile"
                          />
                        </form>
                      </div>
                    </div>
                    <div class="col-12 mt-2">
                      <div class="row">
                        <div class="col-6">
                          <a
                            href="https://www.cvicloud-srv.net/public/batchGen/sample.csv"
                            download
                            class="btn btn btn-success"
                          >
                            <em class="fa fa-download"></em>
                            下載範例</a
                          >
                        </div>
                        <div class="text-right col-6">
                          <a class="btn btn btn-primary" v-on:click="doUpload">
                            <em class="fa fa-upload"></em>
                            上傳產品</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-pane>
              </card>
            </tabs>
          </card>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>
import BaseButton from "../components/BaseButton.vue";
import Card from "../components/Card.vue";
import request from "@/utils/request";
export default {
  name: "BatchGen",
  components: { BaseButton, Card },
  data() {
    return {
      model: {
        file: undefined,
        Creator: "",
        SNprefix: "",
        NamePrefix: "",
        FirmwareID: "",
      },
      Results: [
        {
          Creator: "David",
          CreateTime: "2022-06-08 16:46:40",
          FilePath:
            "https://www.cvicloud-srv.net/public/batch/intns0yfgjndw3kgrffn10lhqe9l1iwv.csv",
          TimeStamp: 1654678000,
          IsReady: true,
          IsError: false,
        },
        {
          Creator: "David",
          CreateTime: "2022-06-10 11:09:33",
          FilePath:
            "https://www.cvicloud-srv.net/public/batch/zfvkqes576cbck11xelsns2g85shojn8.csv",
          TimeStamp: 1654830573,
          IsReady: false,
          IsError: false,
        },
        {
          Creator: "David",
          CreateTime: "2022-06-10 11:09:33",
          FilePath:
            "https://www.cvicloud-srv.net/public/batch/zfvkqes576cbck11xelsns2g85shojn8.csv",
          TimeStamp: 1654830573,
          IsReady: true,
          IsError: true,
        },
      ],
    };
  },
  methods: {
    selectFile() {
      this.model.file = this.$refs.file.files;
    },
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
    async doUpload() {
      if (
        !this.model.file ||
        this.model.Creator === "" ||
        this.model.FirmwareID === "" ||
        this.model.NamePrefix === "" ||
        this.model.SNprefix === ""
      ) {
        alert("必須輸入所有欄位！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.model.file.item(0));
      formData.append("Creator", this.model.Creator);
      formData.append("SNprefix", this.model.SNprefix);
      formData.append("NamePrefix", this.model.NamePrefix);
      formData.append("FirmwareID", this.model.FirmwareID);
      await request
        .post("/BatchGen/Upload", formData, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          alert("已成功上傳產品資訊");
          location.href = this.$config.$project_folder_name + "/batch-gen";
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    },
    async getResults() {
      await request
        .get("/BatchGen/Result", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token.access_token,
          },
        })
        .then((response) => {
          this.Results = response.data.Result;
        })
        .catch((error) => {
          console.log(error);
          location.href = this.$config.$project_folder_name + "/dashboard";
        });
    },
    async removeLink(item) {
      if (
        confirm(
          "是否刪除由 " +
            item.Creator +
            " 於 " +
            item.CreateTime +
            " 所建立的產品資訊?"
        )
      ) {
        await request
          .delete("/BatchGen/Remove", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
            data: {
              link: item.FilePath,
            },
          })
          .then(() => {
            alert("刪除成功");
            this.getResults();
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.error);
          });
      }
    },
  },
  beforeMount() {
    this.getResults();
    this.model.Creator = this.get_cookie("User");
  },
};
</script>
<style></style>
