<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">新增Google Store APP</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a class="btn btn-sm btn-primary" v-on:click="createApp()"
                      >儲存資訊</a
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <h6 class="heading-small text-muted mb-4">上架資訊</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="APP名稱（中英文皆可）"
                      :placeholder="model.appName"
                      input-classes="form-control-alternative"
                      v-model="model.appName"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="專案名稱（英文）"
                      :placeholder="model.folderName"
                      input-classes="form-control-alternative"
                      v-model="model.folderName"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="更新人員"
                      :placeholder="model.editor"
                      input-classes="form-control-alternative"
                      v-model="model.editor"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      label="版本號"
                      :placeholder="model.version"
                      input-classes="form-control-alternative"
                      v-model="model.version"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <!-- <base-input :label="$t('dashboard.dropdownLists.floorsListTitle')"> -->
                    <base-input label="請選擇上架狀態">
                      <select class="form-control" v-model="model.status">
                        <option>已上架</option>
                        <option>停售</option>
                        <option>審核中</option>
                        <option>開發中</option>
                      </select>
                      <!-- <select
                        @change="onFloorChange($event)"
                        v-model="selectedFloor"
                        class="form-control"
                      >
                        <option v-for="floor in floors" v-bind:key="floor.Value">
                          {{ floor.Text }}
                        </option>
                      </select> -->
                    </base-input>
                  </div>
                </div>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  name: "edit-app-store",
  data() {
    return {
      model: {
        appName: "",
        version: "",
        status: "",
        editor: "",
        folderName: "",
      },
    };
  },
  methods: {
    async createApp() {
      await request
        .post(
          "/AppStatus/Android",
          { ...this.model },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("已成功新增上架資訊。");
          location.href = this.$config.$project_folder_name + "/dashboard";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.model.editor = this.$route.query.editor;
  },
};
</script>
<style></style>
