<template>
  <div>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務上線總量"
            type="gradient-green"
            :sub-title="checkHeader.TotalTarget.toString()"
            icon="ni ni-spaceship"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="CviCloud 服務中斷總量"
            type="gradient-red"
            :sub-title="checkHeader.TotalFail.toString()"
            icon="ni ni-fat-remove"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>
        <div class="col-xl-4 col-lg-6">
          <stats-card
            title="上次檢查時間"
            type="gradient-info"
            :sub-title="checkHeader.LastCheckTime"
            icon="ni ni-calendar-grid-58"
            class="mb-4 mb-xl-0"
          >
            <!-- <template v-slot:footer>
              <span class="text-success mr-2">
                <em class="fa fa-arrow-up"></em>
                {{ topStatus.IosLatestLaunchEditor }} 更新於
              </span>
              <span class="text-nowrap">{{ topStatus.IosLatestLaunchAt }}</span>
            </template> -->
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="mb-8"></div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-10 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-xl-8">
                    <h3 class="mb-0">{{ "變更服務：" + model.ServiceName }}</h3>
                  </div>
                  <div class="col-xl-4 text-right">
                    <base-button
                      type="info"
                      icon="ni ni-user-run"
                      v-on:mouseup="save()"
                      >儲存變更</base-button
                    >
                  </div>
                </div>
              </div>
            </template>
            <h4>網址(無法變更)：</h4>
            <p>{{ model.Address }}</p>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  alternative=""
                  label="服務名稱"
                  :placeholder="model.ServiceName"
                  input-classes="form-control-alternative"
                  v-model="model.ServiceName"
                />
              </div>
            </div>
            <div class="row">
              <div class="mr-4 ml-3">
                <span class="form-control-label">是否通知</span>
              </div>
              <div>
                <base-switch :value="model.NeedInfo" v-model="model.NeedInfo" />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱1"
                  :placeholder="model.MailTo[0]"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[0]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱2"
                  :placeholder="model.MailTo[1]"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[1]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱3"
                  :placeholder="model.MailTo[2]"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[2]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱4"
                  :placeholder="model.MailTo[3]"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[3]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <base-input
                  type="email"
                  label="通知信箱5"
                  :placeholder="model.MailTo[4]"
                  input-classes="form-control-alternative"
                  v-model="model.MailTo[4]"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "../components/BaseButton.vue";
import Card from "../components/Card.vue";
import request from "@/utils/request";
export default {
  name: "EditTarget",
  components: { BaseButton, Card },
  data() {
    return {
      model: {
        Address: "",
        ServiceName: "",
        MailTo: [],
        NeedInfo: true,
      },
      checkHeader: {
        TotalFail: 0,
        TotalTarget: 3,
        LastCheckTime: "foo",
      },
    };
  },
  methods: {
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
    async save() {
      await request
        .patch(
          "/AutoCheck/Target",
          {
            ...this.model,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token.access_token,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          alert("變更成功");
          this.$router.push({
            name: "AutoCheck",
          });
        })
        .catch((error) => {
          console.log(error);
          alert(error.response.data.error);
        });
    },
  },
  beforeMount() {
    this.model = this.$store.state.editedTarget;
    this.checkHeader = this.$store.state.checkHeader;
    if (this.model === "") {
      this.$router.push({
        name: "AutoCheck",
      });
    }
  },
};
</script>
<style></style>
