<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">APP Store專案列表</h3>
        </div>
        <div class="col text-right">
          <a
            class="btn btn-sm btn-success"
            v-on:click="createAppStatus()"
            v-if="logined"
            >新增APP</a
          >
          <a class="btn btn-sm btn-primary" v-on:click="getAppStoreList()"
            >重新整理</a
          >
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table thead-classes="thead-light" :data="tableData">
        <template v-slot:columns>
          <th>APP名稱</th>
          <th>最新版號</th>
          <th>目前APP狀態</th>
          <th v-if="logined">編輯上架狀態</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            {{ row.item.appName }}
          </th>
          <td>
            {{ row.item.version }}
          </td>
          <td>
            {{ row.item.status }}
          </td>
          <td v-if="logined">
            <!-- <i
              class="fas fa-arrow-up text-success mr-3"
              :class="
                row.item.bounceRateDirection === 'up'
                  ? 'text-success'
                  : 'text-danger'
              "
            >
            </i> -->
            <base-button
              type="primary"
              size="sm"
              v-on:click="editAppStatus(row.item)"
              >編輯</base-button
            >
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import { Permission, permFlagDefine } from "@/utils/permission";
export default {
  name: "app-store-table",
  data() {
    return {
      tableData: [
        {
          appName: "心電衣",
          version: "1.0.0",
          status: "已上架",
          folderName: "HeartStation",
          editor: "Jerry",
          lastUpdate: "",
        },
      ],
      logined: false,
      user: "",
    };
  },
  methods: {
    getAppStoreList() {
      var that = this;
      this.$axios
        .get(this.$config.$api_url + "/get/AppStoreList", {})
        .then((appStoreList) => {
          that.tableData = appStoreList.data;
          that.tableData.sort(function (a, b) {
            return b["timestamp"] - a["timestamp"];
          });
        });
    },
    createAppStatus() {
      location.href =
        this.$config.$project_folder_name +
        "/create-app-store?editor=" +
        this.user;
    },
    editAppStatus(appdata) {
      location.href = String.format(
        this.$config.$project_folder_name +
          "/edit-app-store?appName={0}&folderName={1}&version={2}&status={3}&editor={4}&lastUpdate={5}",
        appdata.appName,
        appdata.folderName,
        appdata.version,
        appdata.status,
        appdata.editor,
        appdata.lastUpdate
      );
    },
    get_cookie(name) {
      var match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    this.user =
      this.get_cookie("User") == null ? "Guest" : this.get_cookie("User");
    this.logined = new Permission(this.get_cookie("Perm")).hasPermission(
      permFlagDefine.UPstoreManager
    );
    this.getAppStoreList();
    if (!String.format) {
      String.format = function (format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != "undefined" ? args[number] : match;
        });
      };
    }
  },
};
</script>
<style></style>
