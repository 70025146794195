<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="功能清單"
      title="選擇功能分頁"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: '專案列表',
            icon: 'ni ni-bullet-list-67 text-primary',
            path: '/dashboard',
          }"
        />
        <div v-if="logined">
          <sidebar-item
            :link="{
              name: '權限管理',
              icon: 'ni ni-pin-3 text-orange',
              path: '/edit-users',
            }"
          />
        </div>
        <div v-if="logined">
          <sidebar-item
            :link="{
              name: '自動服務檢查',
              icon: 'ni ni-check-bold text-green',
              path: '/auto-check',
            }"
          />
        </div>
        <div v-if="deviceManager">
          <sidebar-item
            :link="{
              name: '批次新增產品',
              icon: 'ni ni-box-2 text-blue',
              path: '/batch-gen',
            }"
          />
        </div>
        <div v-if="synckitManager">
          <sidebar-item
            :link="{
              name: '杏澤更新管理',
              icon: 'ni ni-camera-compact text-orange',
              path: '/synckit',
            }"
          />
        </div>
        <!-- <sidebar-item
          :link="{
            name: 'APP更版記錄',
            icon: 'ni ni-single-02 text-yellow',
            path: '/edit-app-store',
          }"
        /> -->
        <!-- <sidebar-item
          :link="{
            name: 'Tables',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/tables',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Login',
            icon: 'ni ni-key-25 text-info',
            path: '/login',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Register',
            icon: 'ni ni-circle-08 text-pink',
            path: '/register',
          }"
        /> -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import { Permission, permFlagDefine } from "@/utils/permission";
// import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    // ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      logined: false,
      deviceManager: false,
      synckitManager: false,
      user: "",
      perm: 0,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    get_cookie(name) {
      let match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
      );
      if (match) {
        return match[2];
      } else {
        return null;
      }
    },
    toDashboard() {
      location.href = this.$config.$project_folder_name + "/dashboard";
    },
    toEditUsersPage() {
      location.href =
        this.$config.$project_folder_name + "/edit-users?nowUser=" + this.user;
    },
  },
  beforeMount() {
    this.user =
      this.get_cookie("User") == null ? "Guest" : this.get_cookie("User");
    this.logined = this.get_cookie("User") == null ? false : true;
    this.deviceManager =
      this.logined &&
      new Permission(this.get_cookie("Perm")).hasPermission(
        permFlagDefine.UPdeviceManager
      );
    this.synckitManager =
      this.logined &&
      new Permission(this.get_cookie("Perm")).hasPermission(
        permFlagDefine.UPsynckitManager
      );
    //console.log(permission.hasPermission(this.perm, p.UPidentifyManager));
  },
};
</script>
<style lang="scss"></style>
